.App {
	background-image:url('./images/boc-background.png');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.App .night-backdrop {
	position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    background: #000;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(1,22,46,.5) 50%,rgba(0,39,70,.5));
    transition: opacity 1s ease-in-out;
	overflow: hidden;
}

.App .night-backdrop:after {
    content: " ";
    display: block;
    width: 100%;
    padding-right: 2000px;
    height: 100%;
    background: url(./images/clouds.webp) repeat;
    background-size: 2000px auto;
    animation: move-background 120s linear infinite;
    opacity: .15;
}

.App .stats {
	color: white;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 5px;
	position: absolute;
	right: 20px;
	top: 20px;
	padding: 10px 30px;
	background-color: #2b3845;
	text-align: left;
}

.App .stats a {
	color: #fff;
	font-weight: bold;
	text-decoration: none;
}

.App .circle {
	position: absolute;
	width: 600px;
	height: 600px;
	max-width: 100vw;
	max-height: 100vw;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	list-style: none;
}

.App .circle>li .life-circle {
	width: 100px
}

.App .circle a {
	position: absolute;
	left: 50%;
	top: 50px;
	z-index: 1;
	cursor: pointer;
	pointer-events: all;
}

.App .circle a img {
	width: auto;
	height: 40px;
}

.App .circle li:first-child a img {
	height: 30px;
}

.App .circle li:nth-child(2) a img {
	height: 40px;
}

.App .circle>li {
    position: absolute;
    width: var(--zoom);
    left: 50%;
    height: 50%;
    transform-origin: 0 100%;
    pointer-events: none;
    transform: rotate(var(--rot));
}

.App .circle .arrows img {
	position: absolute;
    left: 50%;
	transition: all 0.5s ease-in-out;
}

.App .circle .arrows img.hour-arrow {
	height: 40%;
    top: 30%;
    transform: translate(-50%, 0) rotate(30deg);
}

.App .circle .arrows img.minute-arrow {
	height: 50%;
	top: 25%;
    transform: translate(-50%, 0) rotate(60deg);
}

.App .circle .arrows img.second-arrow {
	height: 65%;
	top: 17.5%;
    transform: translate(-50%, 0) rotate(90deg);
}

@keyframes move-background {
	0% {
		transform: translate3d(-2000px,0,0);
	}
	100% {
		transform: translateZ(0);
	}
}


.App .copyright {
	position: absolute;
	right: 20px;
	bottom: 20px;
	color: white;
	text-align: right;
}
